.std-c-template-search-field {
    position: relative;
}

.std-c-template-search-field__icon-container {
    position: absolute;
    top: 0;
    right: 24px;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.std-c-template-search-field__results-dropdown {
    position: absolute;
    top: calc(100% - 1px);
    left: 0;
    right: 0;
    height: 150px;
    overflow-y: scroll;
    border-radius: 4px;
    border: 1px solid rgb(153, 153, 153);
    box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075),
    0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075),
    0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075);
    background-color: #ffffff;
}

.std-c-template-search-field__results {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.std-c-template-search-field__no_results {
    padding: 8px;
}

.std-c-template-search-field__result-item-btn {
    width: 100%;
    padding: 4px 8px 4px 8px;
    background: transparent;
    border: none;
    text-align: left;
}
