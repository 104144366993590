.std-c-product-template-row__image-container {
    max-width: 300px;
    border: 1px solid rgb(153, 153, 153);
    margin-left: auto;
    margin-right: auto;
}

.std-c-product-template-row__image {
    width: 100%;
    display: block;
}
