.std-c-product-row__image-container {
  max-width: 100px;
  border: 1px solid rgb(153, 153, 153);
  margin-left: auto;
  margin-right: auto;
}

.std-c-product-row__cta-container {
  margin-top: 12px;
}

.std-c-product-row__image {
  width: 100%;
  display: block;
}

.std-c-product-row__warning-message {
  display: flex;
  align-items: center;
}

.std-c-search-header__container {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  flex-direction: column;
}

.std-c-search-header__forms {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  gap: 10px;
}

.std-c-range-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.std-c-range-header-all {
  float: right;
}

.std-c-search-header__field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
